export const formatDate = (date: Date) =>
  date?.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });

export const addWorkDays = (startDate: Date, days: number) => {
  // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
  const dow = startDate.getDay();
  let daysToAdd = days;
  // If the current day is Sunday add one day
  if (dow === 0) daysToAdd += 1;
  // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
  if (dow + daysToAdd >= 6) {
    // Subtract days in current working week from work days
    const remainingWorkDays = daysToAdd - (5 - dow);
    // Add current working week's weekend
    daysToAdd += 2;
    if (remainingWorkDays > 5) {
      // Add two days for each working week by calculating how many weeks are included
      daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
      // Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
      if (remainingWorkDays % 5 === 0) daysToAdd -= 2;
    }
  }
  startDate.setDate(startDate.getDate() + daysToAdd);
  return startDate;
};

// Get n years from today's date
export const getYearsFromNow = (years: number): Date =>
  new Date(new Date().setFullYear(new Date().getFullYear() + years));

const MONTHS_CONVERSION_LIMIT = 36;

enum TimeFrame {
  Years = 'years',
  Months = 'months',
}

export const getRemainingTerm = (months: number): { amount: number; timeFrame: TimeFrame } => {
  if (months > MONTHS_CONVERSION_LIMIT) {
    return {
      amount: Math.floor(months / 12),
      timeFrame: TimeFrame.Years,
    };
  }

  return {
    amount: months,
    timeFrame: TimeFrame.Months,
  };
};

export const formatTextDate = (date: Date) =>
  date?.toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' });

export const formatTextDateAndTime = (date: Date) =>
  date?.toLocaleDateString('en-US', {
    month: 'long',
    day: '2-digit',
    hour: 'numeric',
    minute: date.getMinutes() ? 'numeric' : undefined,
  });

export const formatMonthYear = (date: Date) => date?.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });

export const addDays = (date: Date, days: number) => {
  date.setDate(date.getDate() + days);
  return date;
};

export const addMonths = (date: Date, months: number) => {
  date.setMonth(date.getMonth() + months);
  return date;
};
