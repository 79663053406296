export enum RoutePath {
  Offer = '/offer',
  YourGoal = '/your-goal',
  ExitSurvey = '/exit-survey',
  YourName = '/your-name',
  YourContact = '/your-contact',
  YourBirthDate = '/birthday',
  YourTotalIncome = '/total-income',
  YourAddress = '/address',
  YourCreditReport = '/credit-report',
  OutOfState = '/out-of-state',

  CreatingOffer = '/creating-offer',
  HardOffer = '/hard-offer',
  PayOffPlan = '/pay-off-plan',
  PlanSent = '/plan-sent',

  CardInfo = '/card-info',

  ConfirmLoan = '/confirm-loan',
  DocuSignSignature = '/docu-sign-signature',
  SetupAccount = '/setup-account',
  VerificationSteps = '/verification-steps',
  VerifyingApplication = '/verifying-application',
  Alloy = '/alloy',
  Citizenship = '/citizenship',
  RepaymentMethod = '/repayment-method',
  SetupDeduction = '/setup-deduction',
  SetupViaPayroll = '/setup-via-payroll',
  SetupViaHr = '/setup-via-hr',
  VerifyPayrollDeduction = '/verify-payroll-deduction',

  PreQualificationDecline = '/pre-qualification-decline',

  EmailNotificationAlert = '/email-alert',

  NextSteps = '/next-steps',

  OfferStatus = '/offer-status',

  Feedback = '/feedback',
  FundsSent = '/funds-sent',

  Error = '/error',

  // Financial Checkup
  YourPhoneNumber = '/your-phone-number',
  VerifyPhoneNumber = '/verify-phone-number',
  YourEmail = '/your-email',
  YourIncome = '/your-income',
  YourSavings = '/your-savings',
  YourFinances = '/your-finances',
  YourSSN = '/your-ssn',
  MethodAuth = '/auth',
  Analyzing = '/analyzing',
  CardApplied = '/card-applied',
  HowItWorks = '/how-it-works',

  // Card
  CardApplicationSuccessful = '/card-application-successful',
  ReferralProgram = '/referral-program',
  CardNextSteps = '/card-next-steps',
  XSellLoader = '/xsell-loader',
  ErrorConsolidating = '/error-consolidating',
  ConnectPaycheck = '/connect-paycheck',
  ConnectPaycheckFeedback = '/connect-paycheck-feedback',

  // Not being used
  Location = '/location',
  LoanAmountRange = '/loan-amount',
  CreditScoreRange = '/credit-score',

  // Open DDA
  OpenDDADone = '/open-dda-done',

  // Missed Payments
  MissedPayment = '/missed-payment',
  MakeAPayment = '/make-a-payment',
  MissedPaymentReason = '/missed-payment-reason',
  Plaid = '/employment-verify',
  PlaidCheckingAccounts = '/checking-accounts',
  BankAccount = '/bank-account',
  Authorization = '/authorization',
  WaitingAuthorization = '/waiting-authorization',
  MakingPayment = '/making-payment',
  PaymentInitiated = '/payment-initiated',
  PaymentFailed = '/payment-failed',

  // Student Loan
  StudentLoanEmployerType = '/student-loan-employer-type',
  StudentLoanNonProfitHireDate = '/student-loan-non-profit-hire-date',
  StudentLoanForgivenessAwareness = '/student-loan-forgiveness-awareness',
  StudentLoanNotEnrolled = '/student-loan-not-enrolled',
  StudentLoanSavings = '/student-loan-savings',
  StudentLoanBookAppointment = '/student-loan-book-appointment',
  StudentLoanBookAdvisor = '/student-loan-book-advisor',
  StudentLoanNotEligible = '/student-loan-not-eligible',
  StudentLoanScheduleDetails = '/student-loan-schedule-details',
}
